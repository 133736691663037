export const loginKey = "_USR-TK_";
export const userKey = "_USR_";
export const projectId = "_PID_";
export const projectMenu = "_PM_";
export const projectID = "_PID_";
export const ENCRYPTION_KEY = process.env.REACT_APP_ENCRYPTION_KEY || "";
export const GOOGLE_AUTH_KEY = process.env.REACT_APP_GOOGLE_AUTH || "xxxx";

export const ListOfUseForPalet: string[] = [
  "Website content",
  "Email",
  "Blogging",
  "Content Creation",
  "Ads",
  "Social Posts",
  "Other"
];

export const fileFormat: { name: string; value: string }[] = [
  { name: "PNG", value: "png" },
  { name: "JPEG", value: "jpeg" },
  { name: "PDF", value: "pdf" },
  { name: "SVG", value: "svg" }
];

export const qualityFormat: { name: string; value: string }[] = [
  { name: "High(22.2 mb - 30.6 mb)", value: "High(22.2 mb - 30.6 mb)" },
  { name: "Medium(5.6 mb - 8.4 mb)", value: "Medium(5.6 mb - 8.4 mb)" },
  { name: "Low(609 kb - 946 kb)", value: "Low(609 kb - 946 kb)" }
];

export const downloadSizing: { name: string; value: string }[] = [
  { name: "0.5x", value: "0.5x" },
  { name: "1x", value: "1x" },
  { name: "1.5x", value: "1.5x" },
  { name: "2x", value: "2x" },
  { name: "3x", value: "3x" }
];

export const ReadabilityOptions: { name: string; value: string }[] = [
  {
    name: "Simple",
    value: "Simple"
  },
  {
    name: "Advanced",
    value: "Advanced"
  },
];

export const ToneOptions: { name: string; value: string }[] = [
  {
    name: "Fun",
    value: "Fun"
  },
  {
    name: "Normal",
    value: "Normal"
  },
];

export const fontOptions: { name: string; value: string }[] = [
  { name: "Arial", value: "Arial"  },
  { name: "Helvetica", value: "Helvetica"  },
  { name: "Times New Roman", value: "Times New Roman"  },
  { name: "Courier New", value: "Courier New"  },
  { name: "Arial Black", value: "Arial Black"  },
  { name: "Comic Sans MS", value: "Comic Sans MS"  },
  { name: "Fira Sans", value: "Fira Sans"  },
  { name: "Georgia", value: "Georgia"  },
  { name: "Rubik", value: "Rubik"  },
  { name: "Tahoma", value: "Tahoma"  },
  { name: "Roboto", value: "Roboto"  },
  { name: "Inter", value: "Inter"  },
  { name: "Dancing Script", value: "Dancing Script"  },
  { name: "Poppins", value: "Poppins"  },
  { name: "Recursive", value: "Recursive"  },
  { name: "Oswald", value: "Oswald" }
];

export const Occupation: { name: string; value: string }[] = [
  {
    name: "Graphic Designer",
    value: "Graphic Designer"
  },
  {
    name: "Social Media Manager",
    value: "Social Media Manager"
  },
  {
    name: "Marketing Professional",
    value: "Marketing Professional"
  },
  {
    name: "Content Creator",
    value: "Content Creator"
  },
  {
    name: "Small Business Owner",
    value: "Small Business Owner"
  },
  {
    name: "Educator",
    value: "Educator"
  },
  {
    name: "Nonprofit Organization",
    value: "Nonprofit Organization"
  },
  {
    name: "HR and Recruitment Professional",
    value: "HR and Recruitment Professional"
  },
  {
    name: "Student",
    value: "Student"
  },
  {
    name: "Blogger and Content Writer",
    value: "Blogger and Content Writer"
  },
  {
    name: "Freelancer",
    value: "Freelancer"
  },
  {
    name: "Artists and Creative",
    value: "Artists and Creative"
  },
  {
    name: "Others",
    value: "Others"
  }
];
export const FACEBOOK_ID = process.env.REACT_APP_FACEBOOK_ID || "";
export const FACEBOOK_ADVANCED_ID = process.env.REACT_APP_FACEBOOK_ADVANCED_ID || "";
export const FACEBOOK_CONFIGURATION_ID = process.env.REACT_APP_FACEBOOK_CONFIGURATION_ID || "";
export const INSTAGRAM_ID = process.env.REACT_APP_INSTAGRAM_ID || "";
export const TWITTER_ID = process.env.REACT_APP_TWITTER_ID || "";

export const defaultCanvasSize = {
  width: 640,
  height: 640,
  color: "#ffffff"
};

export const CanvasUnitOfMeasurement: { name: string; value: string }[] = [
  {
    name: "px",
    value: "px"
  },
  {
    name: "em",
    value: "em"
  }
];

export const canvasSizes = {
  instagramPortrait: {
    height: 1080,
    width: 1350
  },
  instagramStory: {
    height: 1080,
    width: 1920
  },
  instagramProfilePicture: {
    height: 800,
    width: 800
  },
  facebookPost: {
    height: 940,
    width: 788
  },
  facebookCover: {
    height: 1640,
    width: 924
  },
  facebookAd: {
    height: 810,
    width: 450
  },
  twitterPost: {
    height: 1600,
    width: 900
  },
  twitterHeader: {
    height: 1500,
    width: 500
  }
};
