import { CanvasCardConfig, CanvasElement } from "interfaces/canvas";
import React, { useContext, useRef, useState } from "react";
import { defaultCanvasSize } from "utils/constants";
export type CanvasProviderValues = {
  projectName: string;
  selectedCanvasMenu: string;
  isExpandedSidebar: boolean;
  canvasRef: React.MutableRefObject<CustomFabricCanvas | null> | any;
  canvaSetup: { config: CanvasCardConfig; elements: CanvasElement[] }[];
  selectedCanvaPage: number;
  pages: any[];
  currentPageIndex: number;
  resetCanvasPage: () => void;
  setCurrentPageIndex: (value: number) => void;
  addNewPage: (value: PageObjectInterface) => void;
  storeProjectName: (value: string) => void;
  storeSelectedCanvasMenu: (value: string) => void;
  setPages: (value: PageObjectInterface[]) => void;
  storeExpandedSidebar: (value: boolean) => void;
  storeCanvasSetup: (
    value: { config: CanvasCardConfig; elements: CanvasElement[] }[]
  ) => void;
  storeSelectedCanvaPage: (value: number) => void;
};
const initState = {
  projectName: "",
  selectedCanvasMenu: "",
  selectedCanvaPage: 1,
  isExpandedSidebar: false,
  canvasRef: null,
  canvaSetup: [
    {
      config: {
        bgColor: "",
        width: 0,
        height: 0
      },
      elements: [
        {
          id: "",
          type: "",
          content: "",
          config: {
            fontSize: undefined,
            color: undefined,
            xAxis: "",
            yAxis: "",
            height: 0,
            width: 0,
            zIndex: 1,
            flipH: false,
            flipV: false
          }
        }
      ]
    }
  ],
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  resetCanvasPage: () => { },
  storeProjectName: (value: string) => void value,
  storeSelectedCanvasMenu: (value: string) => void value,
  storeExpandedSidebar: (value: boolean) => void value,
  addNewPage: (value: PageObjectInterface) => void value,
  setCurrentPageIndex: (value: number) => void value,
  setPages: (value: PageObjectInterface[]) => void value,
  pages: [],
  currentPageIndex: 0,
  storeCanvasSetup: (
    value: { config: CanvasCardConfig; elements: CanvasElement[] }[]
  ) => void value,
  storeSelectedCanvaPage: (value: number) => void value
};
const CanvasContext = React.createContext<CanvasProviderValues>(initState);
export const useCanvasRef = () => {
  return useContext(CanvasContext);
};
export const CanvasProvider = CanvasContext.Provider;
type Props = {
  children?: React.ReactNode;
};
interface CanvasAction {
  type: "add" | "remove" | "modify";
  object: fabric.Object;
  before: any;
  after: any;
}
interface CustomFabricCanvas extends fabric.Canvas {
  historyUndo: CanvasAction[];
  historyRedo: CanvasAction[];
  isUndoing: boolean;
  isRedoing: boolean;
  upperCanvasEl?: HTMLCanvasElement;
}
export interface PageObjectInterface {
  backgroundColor: string;
  objects: any;
  thumbnail: any;
}

export const CanvasProviderContainer: React.FC<Props> = ({ children }) => {
  const canvasRef = useRef<CustomFabricCanvas | null>(null);

  const [currentPageIndex, setCurrentPageIndex] = useState<number>(0);

  // State to control how many pages (divs) to render
  const [pages, setPages] = useState<PageObjectInterface[]>([
    {
      backgroundColor: defaultCanvasSize.color,
      objects: null,
      thumbnail: null,
    }
  ]);

  // useEffect(() => {
  //   // Set the current page ref after new pages are initialized
  //   setCurrentPageIndex(pages.length - 1);
  //   console.log({ pages, pageRefs });
  // }, [pages]);

  const addNewPage = (newPage: PageObjectInterface) => {
    // Add a new page and create a corresponding ref
    setPages((prevPages) => [...prevPages, ...[newPage]]);
  };

  const [isExpandedSidebar, setIsExpandedSidebar] =
    React.useState<boolean>(false);

  const [canvaSetup, setCanvaSetup] = React.useState<
    { config: CanvasCardConfig; elements: CanvasElement[] }[]
  >([
    {
      config: {
        bgColor: defaultCanvasSize.color,
        width: defaultCanvasSize.width,
        height: defaultCanvasSize.height
      },
      elements: []
    }
  ]);

  const resetCanvasPage = () => {
    setPages([]);
    setCurrentPageIndex(0);
  };

  const [selectedCanvaPage, setSelectedCanvaPage] = React.useState<number>(1);
  const [projectName, setProjectName] =
    React.useState<string>("Untitled Project");

  const [selectedCanvasMenu, setSelectedCanvasMenu] =
    React.useState<string>("");

  const storeCanvasSetup = (
    value: { config: CanvasCardConfig; elements: CanvasElement[] }[]
  ) => {
    setCanvaSetup(value);
  };

  const storeProjectName = (value: string) => {
    setProjectName(value);
  };

  const storeExpandedSidebar = (value: boolean) => {
    setIsExpandedSidebar(value);
  };

  const storeSelectedCanvasMenu = (value: string) => {
    setSelectedCanvasMenu(value);
  };

  const storeSelectedCanvaPage = (value: number) => {
    setSelectedCanvaPage(value);
  };
  return (
    <CanvasProvider
      value={{
        projectName,
        canvaSetup,
        canvasRef,
        isExpandedSidebar,
        selectedCanvasMenu,
        selectedCanvaPage,
        storeProjectName,
        storeExpandedSidebar,
        storeSelectedCanvasMenu,
        storeCanvasSetup,
        storeSelectedCanvaPage,
        addNewPage,
        currentPageIndex,
        pages,
        setCurrentPageIndex,
        resetCanvasPage,
        setPages,
      }}
    >
      {children}
    </CanvasProvider>
  );
};
export default CanvasContext;
