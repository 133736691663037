import React, { useEffect, useState } from "react";
import { useLocation } from "react-router";
import { loginKey } from "utils/constants";
import { componentLoader, lazyWithRetry } from "utils/import-utils";
import storage from "utils/storage";

const AuthenticatedApp = lazyWithRetry(() =>
  componentLoader(() => import("layout/AppLayout"))
);

const UnauthenticatedLayout = lazyWithRetry(() =>
  componentLoader(() => import("layout/Unauthenticated/index"))
);

const RouteLayout = () => {
  const location = useLocation();
  const [token, setToken] = useState<string | null>(storage.get(loginKey));

  useEffect(() => {
      setToken(storage.get(loginKey));
  }, [location.pathname]);
  return (
    <div>
      {token ? (
        <AuthenticatedApp />
      ) : (
        <UnauthenticatedLayout />
      )}
    </div>
  );
};

export default RouteLayout;
