import React from "react";
import "./modal.scss";

interface ModalProps {
  children?: React.ReactNode;
  width?: number;
  height?: number | string;
  close?: () => void;
  isOnlyClose?: boolean;
}

export default function Modal<
  P extends { children?: React.ReactNode | ((props: P) => React.ReactNode) }
>({ children, width = 450, close, isOnlyClose, height }: ModalProps) {
  return (
    <div className="modal-el" onClick={!isOnlyClose ? close : undefined}>
      <div
        className="modal-content w-full"
        onClick={(e) => e.stopPropagation()}
        style={{ maxWidth: width, ...(height && {height}) }}
      >
        <div onClick={close}></div>
        {children}
      </div>
    </div>
  );
}
